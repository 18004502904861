//@import "../node_modules/bootstrap/scss/maps"; // Required
//@import "../node_modules/bootstrap/scss/utilities"; // Required
//@import "../node_modules/bootstrap/scss/mixins"; // Required
//@import "../node_modules/bootstrap/scss/root"; // Required
//@import "../node_modules/bootstrap/scss/reboot"; // Required


$primary: #080862;
$secondary: #fd7e14;

@import "../node_modules/bootstrap/scss/functions"; // Required
@import "../node_modules/bootstrap/scss/variables"; // Required

$component-active-bg: $primary;
$nav-pills-link-active-bg: $primary;


// Place variable overrides first, then import just the styles you need. Note that some stylesheets are required no matter what.

@import "../node_modules/bootstrap/scss/bootstrap";

//
// Custom styles
//
